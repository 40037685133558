// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require('popper.js')
require('bootstrap')
require('bootstrap-vue')


import '../stylesheets/fonts.scss';
import '../stylesheets/main.css';
import '../stylesheets/izitoast.scss';

import 'bootstrap-vue/dist/bootstrap-vue.css'
import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/all.css";
//iziToast for nice notifications 
import iziToast from 'izitoast/dist/js/iziToast';
// expose to window
window.iziToast = iziToast;
window.jQuery = $;
window.$ = $;


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import VueTheMask from 'vue-the-mask'


Vue.use(TurbolinksAdapter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(require('vue-moment'));
Vue.use(VueTheMask)

document.addEventListener('turbolinks:load', () => {
  const app = new Vue({
    el: '[data-behavior="vue"]'
  })
});

import axios from 'axios';


import Candidate from '../components/candidate.vue';
import Projects from '../components/projects.vue';
import Project from '../components/project.vue';
import Modal from '../components/modal.vue';
import Settings from '../components/settings.vue';

Vue.component('candidate', Candidate);
Vue.component('projects', Projects);
Vue.component('project', Project);
Vue.component('modal', Modal);
Vue.component('settings', Settings);

