<template>
  <div>
    <div class="card">
      <div class="card-header text-center">
        Настройки
      </div>
      <div class='container'>
			  <label style="margin-top: 30px;">Ссылка на wsdl сервиса</label>
			  <div class="input-group mb-3">
			  	<div class="input-group-prepend" style="width: 38px;">
				  	<span style="height: 38px; margin:0 auto;" v-show="wsdl_alive && !wsdl_checking">
				  		<i class="fas fa-check-circle" style="color:green; 	height:38px; "></i>
				  	</span>
				  	<span style="height: 38px; margin:0 auto;" v-show="!wsdl_alive && !wsdl_checking">
				  		<i class="fas fa-times-circle" style="color:red; 		height:38px; "></i>
				  	</span>
				  	<span style="height: 38px; margin:0 auto;" v-show="wsdl_checking">
				  		<i class="fas fa-sync-alt" style="color:#8fdff0; 	height:38px;"></i>
				  	</span>
				  </div>
				  <input id="wsdl" type="text" :class="['form-control',is_wsdl_valid()]" v-model="wsdl" :disabled="!wsdl_editing">
				  <div class="input-group-append">
				    <button class="btn btn-outline-secondary" type="button" v-if="!wsdl_editing" :disabled="wsdl_checking" @click="check_service">Проверить</button>
				    <button class="btn btn-outline-secondary" type="button" @click="wsdl_editing = true"  v-if="!wsdl_editing" :disabled="wsdl_checking">Редактировать</button>
				    <button class="btn btn-outline-secondary" type="button" @click="update_wsdl"v-if="wsdl_editing && is_wsdl_valid() == ''">Сохранить</button>
				    <button class="btn btn-outline-secondary" type="button" @click="cancel_wsdl_changes" 	v-if="wsdl_editing" >X</button>
				  </div>
				</div>
				<hr>
				<label>email по умолчанию</label>
			  <div class="input-group mb-3">
				  <input type="email" :class="['form-control',is_email_valid()]" v-model='email' :disabled="!email_editing">
				  <div class="input-group-append">
				    <button class="btn btn-outline-secondary" type="button" @click="email_editing = true" v-if="!email_editing" 	>Редактировать</button>
				    <button class="btn btn-outline-secondary" type="button" @click="update_email" 				v-if="email_editing && is_email_valid() == ''" >Сохранить</button>
				    <button class="btn btn-outline-secondary" type="button" @click="cancel_email_changes" v-if="email_editing" >X</button>
				  </div>
				</div>
				<hr>
				<label>время действия сессии (ч.)</label>
			  <div class="input-group mb-3">
				  <input type="text" :class="['form-control',is_time_valid()]" v-mask="'##'" v-model="time" :disabled="!time_editing">
				  <div class="input-group-append">
				    <button class="btn btn-outline-secondary" type="button" @click="time_editing = true"	v-if="!time_editing">Редактировать</button>
				    <button class="btn btn-outline-secondary" type="button" @click="update_time"					v-if="time_editing && is_time_valid() == ''">Сохранить</button>
				    <button class="btn btn-outline-secondary" type="button" @click="cancel_time_changes" 	v-if="time_editing" >X</button>
				  </div>
				</div>
				<hr>
				<label>Secret key</label>
			  <div class="input-group mb-3">
				  <input type="text" class="form-control" v-model="key" :disabled="!key_editing">
				  <div class="input-group-append">
				    <button class="btn btn-outline-secondary" type="button" @click="key_editing = true"		v-if="!key_editing">Редактировать</button>
				    <button class="btn btn-outline-secondary" type="button" @click="update_key"						v-if="key_editing">Сохранить</button>
				    <button class="btn btn-outline-secondary" type="button" @click="cancel_key_changes" 	v-if="key_editing" >X</button>
				  </div>
				</div>
			</div>
    </div>
  </div>
</template>

<script>
	import axios from 'axios';
	import TheMask from 'vue-the-mask'
	export default {
		components: {TheMask},
	  name: 'Settings',
	  props: [],
	  data() {
	    return {
	    	settings: {},
	    	wsdl: '',
	    	wsdl_alive: false,
	    	wsdl_editing: false,
	    	wsdl_checking: true,
	    	wsdl_reg: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([a-z0-9]|[0-9])+([\-\.]{1}[a-z0-9]+)*(:[0-9]{1,5})?(\/.*)?$/,
	    	email: '',
	    	email_editing: false,
	    	time_editing: false,
	    	time: '',
    		email_reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    		key: '',
    		key_editing: false
	    }
	  },
	  created() {
	  	this.check_service();
	  	this.get_settings();
	  },
	  methods: {
	  	update_key(){
	  		if (this.key != this.settings.secret_key) {
		  		axios.post('/update_settings', {
		        setting: 'key',
		        value: this.key
		      })
		      .then((response) => {
	      	  if (response.data.status == "ok") {
		          iziToast.info({
		            message: 'Ключ успешно обновлен.'
		          });
		          this.check_service();
		      	} else {
		      		iziToast.error({
		            message: 'Проверка сервиса не прошла.'
		          });
		      		this.key = this.settings.secret_key
		      	}
		      })
		      .catch((error) => {
		        console.log(error);
		      });
		    } else {
		    	console.log('не требует обновления');
		    }
		    this.key_editing = false;
	  	},
	  	cancel_key_changes(){
		  	this.key = this.settings.secret_key;
	  		this.key_editing = false;
		  },
	  	update_wsdl(){
	  		if (this.wsdl != this.settings.wsdl) {
		  		axios.post('/update_settings', {
		        setting: 'wsdl',
		        value: this.wsdl
		      })
		      .then((response) => {
	      	  if (response.data.status == "ok") {
		          iziToast.info({
		            message: 'Ссылка успешно обновлена.'
		          });
		          this.check_service();
		      	} else {
		      		iziToast.error({
		            message: 'Проверка сервиса не прошла.'
		          });
		      		this.wsdl = this.settings.wsdl
		      	}
		      })
		      .catch((error) => {
		        console.log(error);
		      });
		    } else {
		    	console.log('не требует обновления');
		    }
		    this.wsdl_editing = false;
	  	},
	  	cancel_wsdl_changes(){
		  	this.wsdl = this.settings.wsdl;
	  		this.wsdl_editing = false;
		  },
	  	is_wsdl_valid(){
	  		let result = '';
	  		if (this.wsdl == "" || !this.wsdl_reg.test(this.wsdl) ){
	  			result = "is-invalid";
	  		} 
	  		else {
	  		}
	  		return result;
		  },
		  update_time(){
	  		if (this.time != this.settings.session_time) {
		  		axios.post('/update_settings', {
		        setting: 'time',
		        value: this.time
		      })
		      .then((response) => {
	      	  if (response.data.status == "ok") {
		          iziToast.info({
		            message: 'Время успешно обновлено.'
		          });
		      	}
		      })
		      .catch((error) => {
		        console.log(error);
		      });
		    } else {
		    	console.log('не требует обновления');
		    }
		    this.time_editing = false;
	  	},
	  	cancel_time_changes(){
		  	this.time = this.settings.session_time;
	  		this.time_editing = false;
		  },
		  is_time_valid(){
		  	return (this.time != '' && this.time <=24 && this.time > 0) ? '' : 'is-invalid'
		  },
	  	update_email(){
	  		if (this.email != this.settings.default_email) {
		  		axios.post('/update_settings', {
		        setting: 'email',
		        value: this.email
		      })
		      .then((response) => {
	      	  if (response.data.status == "ok") {
		          iziToast.info({
		            message: 'Email успешно обновлен.'
		          });
		      	}
		      })
		      .catch((error) => {
		        console.log(error);
		      });
		    } else {
		    	console.log('не требует обновления');
		    }
		    this.email_editing = false;
	  	},
	  	cancel_email_changes(){
	  		this.email = this.settings.default_email;
	  		this.email_editing = false;
	  	},
	   	is_email_valid(){
	   		let result = '';
	  		if (this.email == "" || !this.email_reg.test(this.email) ){
	  			result = "is-invalid";
	  		} 
	  		else {
	  		}
	  		return result;
	  	},
	  	get_settings(){
	  		axios.get('/settings.json')
		  	.then((response) => {
		  		this.settings = response.data;	
		  		this.email = this.settings.default_email;
		  		this.wsdl = this.settings.wsdl;
		  		this.time = this.settings.session_time;
		  		this.key = this.settings.secret_key;
		  	});
		  },
	  	check_service(){
	  		this.wsdl_checking = true;
	  		axios.get('/check_service')
			  .then( (response) => {
			  	this.wsdl_alive = (response.data.status == 'alive') ? true : false;
			    this.wsdl_checking = false;
			  })
			  .catch( (error) => {
			    // handle error
			    console.log(error);
			  });
	  	}
	  }
	};
</script>