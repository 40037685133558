// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Acrom-Regular.woff");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face{font-family:'Acrom';font-style:normal;font-weight:400;src:local(\"Acrom Regular\") local(\"Acrom-Regular\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\")}\n", "",{"version":3,"sources":["/Users/sork/Web Development/roslk/app/javascript/stylesheets/fonts.scss"],"names":[],"mappings":"AAAA,WACE,mBAAoB,CACpB,iBAAkB,CAClB,eAAgB,CAChB,wGACoD","file":"fonts.scss","sourcesContent":["@font-face {\n  font-family: 'Acrom';\n  font-style: normal;\n  font-weight: 400;\n  src: \tlocal(\"Acrom Regular\") local(\"Acrom-Regular\"),\n  \t\t\turl(\"../fonts/Acrom-Regular.woff\") format(\"woff\");\n}"]}]);
// Exports
module.exports = exports;
