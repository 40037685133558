<template>
	<tr class="custom-table__tr custom-table__hover">
    <td class="custom-table__td custom-table__border">
      <div class="custom-table__content collapser" :class="{ active: !isCollapsed }">
        <div class="name">
          <div class="name__fullname">
            {{candidate.name}}
          </div>
        </div>
        <div class="comment">
          <a class="comment__item comment__item_add" @click.stop.prevent="requestComment()">{{ locale == 'en' ? 'comment' : 'комментарий' }}</a>
          <span v-for="comment in candidate.comments" class="comment__item" :title="comment.author + ': ' + comment.text">{{ locale == 'en' ? 'comment from ' : 'комментарий от ' }}{{comment.created_at | moment("DD.MM.YY")}} </span>
        </div>
      </div>
    </td>
    <td class="custom-table__td custom-table__border">
      <div class="custom-table__content collapser" :class="{ active: !isCollapsed }">
        {{candidate.current_company}}
      </div>
    </td>
    <td class="custom-table__td custom-table__border">
      <div class="custom-table__content collapser" :class="{ active: !isCollapsed }">
        {{candidate.current_position}}
      </div>
    </td>
    <td class="custom-table__td custom-table__border">
      <div class="custom-table__content collapser" :class="{ active: !isCollapsed }">
        <pre> {{candidate.experience}}</pre>
      </div>
    </td>
    <td class="custom-table__td custom-table__border">
      <div class="custom-table__content collapser text-center" :class="{ active: !isCollapsed }">
        <span style="font-size: 1.2em"> <b-badge v-b-tooltip.hover :title="candidate.comment" :variant="candidate.comment == '' ? 'secondary' : 'info'">{{candidate.status}}</b-badge></span>
      </div>
    </td>
    <td class="custom-table__td custom-table__border">
      <div class="custom-table__content collapser d-flex justify-content-between" :class="{ active: !isCollapsed }">
        <button type="submit" class="button-icon button-icon_file button-icon_file_cv" @click="downloadFile(candidate.file_conrep)" :disabled="candidate.file_conrep == null"></button>
        <button type="button" class="button-icon button-icon_file button-icon_file_r" @click="downloadFile(candidate.file_recomm)" :disabled="candidate.file_recomm == null"></button>
        <button type="button" class="button-icon button-icon_file button-icon_file_a" @click="downloadFile(candidate.file_scores)" :disabled="candidate.file_scores == null"></button>
      </div>
    </td>
    <td class="custom-table__td custom-table__border">
      <div class="custom-table__content"><button class="open-btn" @click="toggle_collapse($event)"></button></div>
    </td>
  </tr>
</template>

<style>
   pre {
      overflow-x: auto;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
      font-family: Acrom, sans-serif;
   }
</style>

<script>
  import axios from 'axios';
	export default {
	  name: 'Candidate',
	  props: {
	  	candidate: Object,
      locale: ''
	  },
	  data() {
	    return {
	    	project: Object,
	    	comments:[],
	    	isCollapsed: true
	    }
	  },
	  methods: {
	  	toggle_collapse() {
	  		this.isCollapsed = !this.isCollapsed;
	  	},
      requestComment(){
        let data = this.candidate;
        this.$emit('commentRqstd', data);
      },
      downloadFile(file_id){
        axios.get('/get_file.json?file_id='+file_id,)
        .then((response) => { 
          if (response.data.file_status == 'present') {
            window.open('/get_file?file_id='+file_id,'_blank');
          } else {
            iziToast.error({
              message: 'Невозможно получить файл.'
            });
          }
        });
      }
	  },
	}
</script>