<template>
  <div class="modal fade show addComment" id="addComment" tabindex="-1" role="dialog" aria-labelledby="addComment" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{modalData.title}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="addComment__title">
            {{modalData.subtitle}}
          </div>
          <div v-if="modalData.locale == 'en'"class="custom-textarea custom-textarea_large" data-content="Your comment">
            <textarea v-model="text"></textarea>
          </div>
          <div v-else class="custom-textarea custom-textarea_large" data-content="Ваш комментарий">
            <textarea v-model="text"></textarea>
          </div>
          <div class="addComment__footer">
            <button type="submit" class="button button_medium button__full-blue" @click="submit">{{ modalData.locale == 'en' ? 'Send' : 'Отправить' }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: ['modalData'],
  data() {
    return {
      text: ''
    }
  },
  methods: {
    submit() {
      if (this.text != '') { 
        let data = {};
        data.message = this.text;
      	if (this.modalData.about == 'project') {
          data.about = 'project';
          data.company = this.modalData.title;
          data.project = this.modalData.subtitle;
          data.manager_email = this.modalData.manager_email;
      		this.$emit('commentRcvd', data);
      	}
      	else if (this.modalData.about == 'candidate') {
          data.about = 'candidate';
          data.project_id = this.modalData.project_id;
          data.candidate_id = this.modalData.candidate_id;
      		this.$emit('commentRcvd', data);
      	}
      }
      $('#addComment').modal('hide');
      this.text = '';
    },
    show() {
      $('#addComment').modal('show');
    }
  }
};
</script>