<template>
  <div class="table-main">
  	<div v-if='!loaded' class="text-center" style="margin-top: 100px;"> <b-spinner type="grow" variant="info" label="Spinning"></b-spinner></div>
  	<div v-if='loaded && candidates.length < 1' class="not-found">
      <img v-bind:src="require('404.svg')">
      <div v-if="locale == 'en'" class="not-found__msg">There are no candidates at the moment.</div>
      <div v-else class="not-found__msg">В данный момент кандидатов нет.</div>
    </div>
    <div v-if='loaded && candidates.length >= 1'>
    <div class="table-main__top">
      <div class="table-main__title">
        <a class="button-icon button-icon_back" href='/projects'></a>
        {{project.company}} / {{project.position}}
        <span>({{candidates.length}})</span>
      </div>
      <div class="table-main__buttons">
        <div class="input-custom">
	        <input v-if="locale == 'en'" placeholder="Quick search" class="input-custom__filter" v-model="search">
          <input v-else placeholder="Быстрый поиск" class="input-custom__filter" v-model="search">
	      </div>
        <button type="button" class="button button__comment" @click="getComment()">{{ locale == 'en' ? 'Leave comment' : 'Ваш комментарий' }}</button>
      </div>
    </div>
    <div class="custom-table-wrap">
      <table class="custom-table custom-table_inside">
        <tr class="custom-table__tr">
          <th class="custom-table__th"><button type="button" class="sort-btn" @click="setSort('name')">{{ locale == 'en' ? 'Name' : 'ФИО' }}</button></th>
          <th class="custom-table__th">{{ locale == 'en' ? 'Current company' : 'Место' }}</th>
          <th class="custom-table__th">{{ locale == 'en' ? 'Position' : 'Должность' }}</th>
          <th class="custom-table__th">{{ locale == 'en' ? 'Experience' : 'Опыт' }}</th>
          <th class="custom-table__th"><button type="button" class="sort-btn" @click="setSort('status')">{{ locale == 'en' ? 'Status' : 'Статус' }}</button></th>
          <th class="custom-table__th text-center">{{ locale == 'en' ? 'Files' : 'Файлы' }}</th>
          <th class="custom-table__th"></th>
        </tr>
        <candidate @commentRqstd="requestComment" v-for="candidate in filteredCandidates" :candidate="candidate" :locale="locale" :key='candidate.record_id'></candidate>
      </table>
      
    </div>
    </div>
    <modal @commentRcvd="proceedComment" ref="modal" v-bind:modalData="modalData"></modal>
  </div>
</template>
<script>
	import Candidate from "./candidate";
	import Modal from "./modal";
	import axios from 'axios';
	export default {
	  name: 'Project',
	  components: {Candidate},
	  props: ['project_id'],
	  data() {
	    return {
	    	project: Object,
	    	locale: '',
	    	candidates: [],
	    	search: '',
	    	sortBy: '',
	    	isSortAsc: true,
	    	modalData: {},
	    	loaded: false
	    }
	  },
	  computed: {
	  	filteredCandidates() {
	  		return this.candidates.filter(item => this.metaMatch(item,this.search))
	  													.sort((a, b) => this.sortFunction(a,b,this.sortBy)) 
	  	}
	  },
	  created() {
	  	fetch('/projects/'+this.project_id+'.json')
	  	.then(response => response.json())
	  	.then(data => {	this.candidates = data.candidates;
	  									this.project = data.project;
	  									this.loaded = true;
	  									this.locale = data.locale
	  								});
	  },
	  methods: {
	  	proceedComment(data){
	  		switch(data.about) {
				  case 'project':  
				  	this.sendMail(data);
				  	break;
				  case 'candidate': 
				  	this.addComment(data);
				  	break;
				  default:
				    console.log('smthng went wrong');
				}

	  	},
	  	getComment(){
	  		this.modalData = {"title": this.project.company, "subtitle": this.project.position, "about": "project", "manager_email": this.project.manager_email, "locale": this.locale};
	      this.emailToInform = this.project.manager_email;
	      this.$refs.modal.show();
	  	},
	  	addComment(data){
	  		axios.post('/save_comment', {
	        message: data.message,
	        project_id: data.project_id,
	        candidate_id: data.candidate_id
	      })
	      .then((response) => {
	      	if (response.status == 200) {
	        	let i = this.candidates.findIndex(k => k.record_id == data.candidate_id);
	        	this.candidates[i].comments.push(response.data);
	          iziToast.info({
	            message: 'Ваш комментарий добавлен.'
	          });
	        }
	      })
	      .catch((error) => {
	        console.log(error);
	      });

	  	},
	  	requestComment(candidate){
	  		this.modalData = {"title": this.project.position, "subtitle": candidate.name, "about": "candidate", project_id: this.project.project_id, candidate_id: candidate.record_id, "locale": this.locale};
	      this.emailToInform = this.project.manager_email;
	      this.$refs.modal.show();
	  	},
	  	sendMail(data){
	      axios.post('/send_email', {
	        message: data.message,
	        company: data.company,
	        project: data.project,
	        mail_to: data.manager_email
	      })
	      .then((response) => {
	        if (response.data.status == 'ok') {
	          iziToast.info({
	            message: 'Ваш комментарий отправлен.'
	          });
	        }
	        console.log(response.data);
	      })
	      .catch((error) => {
	        console.log(error);
	      });
	    },
	  	setSort(word){
	  		if (word == this.sortBy) this.isSortAsc = !this.isSortAsc
	  		this.sortBy = word
	  	},
	  	metaMatch(person,search) {
	  		search = search.toLowerCase();
	    	if (person.name?.toLowerCase().includes(search) 
	    		|| person.experience?.toLowerCase().includes(search)
	    		|| person.current_position?.toLowerCase().includes(search)
	    		|| person.current_company?.toLowerCase().includes(search)
	    		|| person.status?.toLowerCase().includes(search))
		      return true;
		    else
		  		return false;
		  	end
	    },
	    sortFunction(a,b,sortBy) {
	    	let result = 0
	    	if (a[sortBy] > b[sortBy])
	    		result = 1
	    	else
	    		result = -1
	    	if (this.isSortAsc) result = result * -1
	    	return result
	    }
	  }
	}
</script>