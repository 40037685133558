<template>
  <div class="table-main">
    <div v-if='!loaded' class="text-center" style="margin-top: 100px;"> <b-spinner type="grow" variant="info" label="Spinning"></b-spinner></div>
    <div v-if='loaded && projects.length < 1' class="not-found">
      <img v-bind:src="require('404.svg')">
      <div v-if="locale == 'en'" class="not-found__msg">You don't have any active projects</div>
      <div v-else class="not-found__msg">У Вас нет активных проектов</div>
    </div>
    <div v-if='loaded && projects.length >= 1'>
      <div class="table-main__top">
        <div class="table-main__title">{{ locale == 'en' ? 'Projects' : 'Проекты' }}<span>({{projects.length}})</span></div>
        <div class="table-main__buttons">
          <div class="input-custom">
            <input v-if="locale == 'en'" placeholder="Quick search" class="input-custom__filter" v-model="search">
            <input v-else placeholder="Быстрый поиск" class="input-custom__filter" v-model="search">
          </div>
        </div>
      </div>
      <div class="custom-table-wrap">
        <table class="custom-table custom-table_projects">
          <tr class="custom-table__tr">
            <th class="custom-table__th"><button type="button" class="sort-btn" @click="setSort('company')">{{ locale == 'en' ? 'Company' : 'Компания' }}</button></th>
            <th class="custom-table__th"><button type="button" class="sort-btn" @click="setSort('position')">{{ locale == 'en' ? 'Position' : 'Должность' }}</button></th>
            <th class="custom-table__th">{{ locale == 'en' ? 'Candidates' : 'Кандидаты' }}</th>
            <th class="custom-table__th">{{ locale == 'en' ? 'Consultant' : 'Менеджер' }}</th>
          </tr>
          <tr v-for="project in filteredProjects" class="custom-table__tr custom-table__hover" @click="openProject(project.project_id)">
            <td class="custom-table__td custom-table__border">
              <div class="custom-table__content">
                <div class="name">
                  <div class="name__fullname">{{project.company}}</div>
                </div>
                <div class="comment"><a href="" class="comment__item comment__item_add" @click.stop.prevent="getComment(project)">{{ locale == 'en' ? 'comment' : 'комментарий' }}</a></div>
              </div>
            </td>
            <td class="custom-table__td custom-table__border">
              <div class="custom-table__content">{{project.position}}</div>
            </td>
            <td class="custom-table__td custom-table__border">
              <div class="custom-table__content">{{ locale == 'en' ? 'Presented' : 'Представлен' }} – <span class="color_dark">{{project.candidates_presented}}</span>, {{ locale == 'en' ? 'In progress' : 'В работе' }} - <span class="color_blue">{{project.candidates_progress}}</span>, {{ locale == 'en' ? 'Excluded' : 'Исключен' }} – <span class="color_gray">{{project.candidates_excluded}}</span></div>
            </td>
            <td class="custom-table__td custom-table__border">
              <div class="custom-table__content">{{project.manager_name}} <span class="color_blue"> <a :href="'mailto:'+project.manager_email">{{project.manager_email}}</a></span></div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <modal @commentRcvd="sendMail" ref="modal" v-bind:modalData="modalData"></modal>
  </div>
</template>
<script>
import Modal from "./modal";
import axios from 'axios';
export default {
  name: 'Projects',
  components: {Modal},
  props: [],
  data() {
    return {
      locale: '',
      projects: [],
      loaded: false,
      no_projects: true,
      search: '',
      sortBy: '',
      isSortAsc: true,
      loaded: false,
      modalData: {},
      emailToInform: ''
    }
  },
  created() {
    fetch('/projects.json')
      .then(response => {
        if (response.status == 500){
          this.projects = []
          this.loaded = true;
        }
        return response.json();
      })
      .then(data => {
        if(!this.loaded){
          this.projects = data.projects;
          this.locale = data.locale;
          this.loaded = true;
        }
      });
  },
  computed: {
    filteredProjects() {
      return this.projects.filter(item => this.metaMatch(item, this.search))
        .sort((a, b) => this.sortFunction(a, b, this.sortBy))
    }
  },
  methods: {
    sendMail(data){
      axios.post('/send_email', {
        message: data.message,
        company: data.company,
        project: data.project,
        mail_to: data.manager_email
      })
      .then((response) => {
        if (response.data.status == 'ok') {
          iziToast.info({
            message: 'Ваш комментарий отправлен.'
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },
    getComment(project) {
      this.modalData = {"title": project.company, "subtitle": project.position, "about": "project", "manager_email": project.manager_email, "locale": this.locale };
      this.emailToInform = project.manager_email;
      this.$refs.modal.show();
    },
    openProject(project_id) {
      window.location.href = '/projects/' + project_id
    },
    setSort(word) {
      if (word == this.sortBy) this.isSortAsc = !this.isSortAsc
      this.sortBy = word
    },
    metaMatch(project, search) {
      search = search.toLowerCase();
      if (project.company.toLowerCase().includes(search) ||
        project.position.toLowerCase().includes(search))
        return true;
      else
        return false;
      end
    },
    sortFunction(a, b, sortBy) {
      let result = 0
      if (a[sortBy] > b[sortBy])
        result = 1
      else
        result = -1
      if (this.isSortAsc) result = result * -1
      return result
    }
  }
}
</script>